<template>
  <b-form @submit.prevent>

    <b-row>
      <b-col cols="12">
        <form-wizard
          ref="activateAll"
          color="#7367F0"
          title=""
          subtitle=""
          shape="square"
          finish-button-text="Сохранить"
          back-button-text="Назад"
          next-button-text="Вперед"
          class="mb-3"
        >
          <tab-content title="Акт Приема-передачи">
            <b-row>
              <b-col cols="6">
                <b-form-group
                  label="Выбор компании:"
                  label-for="company_id"
                >
                  <b-form-select
                    id="company_id"
                    v-model="ordersData.organization_id"
                    :options="allCompanies"
                    :text-field="'name'"
                    :value-field="'id'"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Дата создания:"
                  label-for="created_at"
                >
                  <flat-pickr
                    id="created_at"
                    v-model="ordersData.created_at"
                    class="form-control"
                    :config="{ enableTime: false, dateFormat: 'd-m-Y H:i'}"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="6">
                <b-form-group
                  label="ФИО:"
                  label-for="fio"
                >
                  <b-form-input
                    id="fio"
                    v-model="ordersData.full_name"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Номер телефона:"
                  label-for="phone"
                >
                  <b-input-group>
                    <cleave
                      id="phone"
                      v-model="ordersData.phone"
                      class="form-control"
                      :raw="true"
                      :options="options.DelimetrPhone"
                      placeholder="+7 900 555 35 35"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="6">
                <b-form-group
                  label="VIN номер:"
                  label-for="vin"
                >
                  <b-form-input
                    id="vin"
                    v-model="ordersData.auto_info_vin"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Гос. номер:"
                  label-for="gos_number"
                >
                  <cleave
                    id="gos_number"
                    v-model="ordersData.auto_info_gos_nomer"
                    class="form-control"
                    :raw="true"
                    :options="options.DelimetrGosNomer"
                    placeholder="Пример: ['E' '000' 'EE' '000'  ]"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="6">
                <b-form-group
                  label="Марка автомобиля:"
                  label-for="type_car"
                >
                  <b-form-input
                    id="type_car"
                    v-model="ordersData.auto_info_mark"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Модель автомобиля:"
                  label-for="model_car"
                >
                  <b-form-input
                    id="model_car"
                    v-model="ordersData.auto_info_model"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="6">
                <b-form-group
                  label="Год выпуска автомобиля:"
                  label-for="year_car"
                >
                  <b-form-input
                    id="year_car"
                    v-model="ordersData.auto_info_year"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Пробег автомобиля:"
                  label-for="auto_info_milage"
                >
                  <b-form-input
                    id="auto_info_milage"
                    v-model="ordersData.auto_info_milage"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Примечание:"
                  label-for="note"
                >
                  <b-form-textarea
                    id="note"
                    v-model="ordersData.note"
                    rows="3"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <hr>

            <b-row>
              <b-col cols="12">
                <p class="font-weight-bold">
                  Заказчик передал, а Исполнитель принял следующие документы:
                </p>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="6">
                <b-form-group
                  label="Свидетельство о регистрации транспортного средства/ПТС:"
                  label-for="sts_pts_take"
                >
                  <b-form-checkbox
                    id="sts_pts_take"
                    v-model="ordersData.sts_pts_take"
                    class="mt-2"
                    switch
                    inline
                    value="да"
                    unchecked-value="нет"
                  >
                    <span v-if="ordersData.sts_pts_take === 'да'">У нас</span>
                    <span v-else>У владельца</span>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Документ удостоверяющий личность:"
                  label-for="passport_take"
                >
                  <b-form-checkbox
                    id="passport_take"
                    v-model="ordersData.passport_take"
                    class="mt-2"
                    switch
                    inline
                    value="да"
                    unchecked-value="нет"
                  >
                    <span v-if="ordersData.passport_take === 'да'">У нас</span>
                    <span v-else>У владельца</span>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="6">
                <b-form-group
                  label="Сервисная книжка на автомобиль:"
                  label-for="serviseBook_take"
                >
                  <b-form-checkbox
                    id="serviseBook_take"
                    v-model="ordersData.serviseBook_take"
                    class="mt-2"
                    switch
                    inline
                    value="да"
                    unchecked-value="нет"
                  >
                    <span v-if="ordersData.serviseBook_take === 'да'">У нас</span>
                    <span v-else>У владельца</span>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Доверенность:"
                  label-for="doverennost_take"
                >
                  <b-form-checkbox
                    id="doverennost_take"
                    v-model="ordersData.doverennost_take"
                    class="mt-2"
                    switch
                    inline
                    value="да"
                    unchecked-value="нет"
                  >
                    <span v-if="ordersData.doverennost_take === 'да'">У нас</span>
                    <span v-else>У владельца</span>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="6">
                <b-form-group
                  label="Ключь:"
                  label-for="key_take"
                >
                  <b-form-checkbox
                    id="key_take"
                    v-model="ordersData.key_take"
                    class="mt-2"
                    switch
                    inline
                    value="да"
                    unchecked-value="нет"
                  >
                    <span v-if="ordersData.key_take === 'да'">У нас</span>
                    <span v-else>У владельца</span>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Запасные части, материалы и т.п:"
                  label-for="list_materials_take"
                >
                  <b-form-checkbox
                    id="list_materials_take"
                    v-model="ordersData.list_materials_take"
                    class="mt-2"
                    switch
                    inline
                    value="да"
                    unchecked-value="нет"
                  >
                    <span v-if="ordersData.list_materials_take === 'да'">У нас</span>
                    <span v-else>У владельца</span>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>

            <hr>

            <b-row>
              <b-col cols="6">
                <p>По желанию заказчика автомобиль на предмет наружных повреждений и других дефектов не осматривался; имущество, находящееся в салоне, не описывалось:</p>
              </b-col>
              <b-col cols="6">
                <b-form-checkbox
                  id="osmotr_auto"
                  v-model="ordersData.osmotr_auto"
                  checked="true"
                  class="mt-2"
                  switch
                  inline
                >
                  <span v-if="ordersData.osmotr_auto === true">Не осматривался</span>
                  <span v-else>Осматривался</span>
                </b-form-checkbox>
              </b-col>
            </b-row>

            <hr>

            <b-row>
              <b-col cols="6">
                <p>Замененные запчасти вернуть клиенту:</p>
              </b-col>
              <b-col cols="6">
                <b-form-checkbox
                  id="spare_parts"
                  v-model="ordersData.spare_parts"
                  checked="true"
                  switch
                  inline
                >
                  <span v-if="ordersData.spare_parts === true">Отдать</span>
                  <span v-else>Оставляем себе</span>
                </b-form-checkbox>
              </b-col>
            </b-row>

            <hr>

            <b-row>
              <b-col cols="12">
                <p class="font-weight-bold">
                  Порядок согласования дополнительных работ с Заказчиком:
                </p>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="6">
                <p>- Согласование по телефону:</p>
              </b-col>
              <b-col cols="6">
                <b-form-checkbox
                  id="phone_aprove"
                  v-model="ordersData.phone_aprove"
                  checked="true"
                  switch
                  inline
                >
                  <span v-if="ordersData.phone_aprove === true">Можно</span>
                  <span v-else>Нет нельзя</span>
                </b-form-checkbox>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="6">
                <p>- Согласование только при личном присутствии:</p>
              </b-col>
              <b-col cols="6">
                <b-form-checkbox
                  id="personal_aprove"
                  v-model="ordersData.personal_aprove"
                  checked="true"
                  switch
                  inline
                >
                  <span v-if="ordersData.personal_aprove === true">Да</span>
                  <span v-else>Нам доверяют</span>
                </b-form-checkbox>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="6">
                <p>- Без согласования выполнять доп работы на сумму не более:</p>
              </b-col>
              <b-col cols="6">
                <b-form-input
                  id="max_sum_aprove"
                  v-model="ordersData.max_sum_aprove"
                />
              </b-col>
            </b-row>

            <hr>

            <b-row>
              <b-col cols="6">
                <b-form-group
                  label="Тип оплаты:"
                  label-for="type_pay"
                >
                  <b-form-select
                    id="type_pay"
                    v-model="ordersData.type_pay"
                    :options="[{id: null, name: ' ---------- '},{id: 1, name: 'Наличными'},{id: 2, name: 'Картой'}]"
                    :text-field="'name'"
                    :value-field="'id'"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Сумма предоплаты:"
                  label-for="sum"
                >
                  <b-form-input
                    id="sum"
                    v-model="ordersData.sum"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="ordersData.type_pay === 2">
              <b-col cols="12">
                <b-form-group
                  label="% надбавки за безнал:"
                  label-for="percent_beznal"
                >
                  <b-form-input
                    id="percent_beznal"
                    v-model="ordersData.percent_beznal"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <p>Статус:</p>
              </b-col>
              <b-col cols="10">
                <b-form-select
                  id="status"
                  v-model="ordersData.status_order"
                  :options="[{id: 1, name: 'Новый заказ'},{id: 3, name: 'Завершено'},{id: 4, name: 'Резерв'}]"
                  :text-field="'name'"
                  :value-field="'id'"
                />
              </b-col>
            </b-row>
          </tab-content>

          <tab-content title="Заказ наряд">
            <h3>Работы</h3>
            <work-row
              v-for="(item, index) in ordersData.works_data.items"
              :id="'works' + item.id"
              :key="'works' + item.id + '-' + ordersData.id"
              :row-data="item"
              :row-index="index"
              @removeWorkItem="removeWorkItem"
            />
            <b-row>
              <b-col cols="5">
                <b-button
                  variant="outline-success"
                  class="btn-icon"
                  @click="repeatWorksAgain"
                >
                  <feather-icon
                    icon="PlusCircleIcon"
                    class="text-success"
                  />
                  Новая строка
                </b-button>
              </b-col>
              <b-col cols="3">
                <strong>Сумма: </strong> {{ calculateWorkSum }}
              </b-col>
              <b-col cols="4">
                <strong>Сумма со скидкой: </strong> {{ calculateWorkSumDiscount }}
              </b-col>
            </b-row>
            <hr style="border-top: 3px solid #ebe9f1;">
            <h3>Запчасти</h3>
            <part-row
              v-for="(item, index) in ordersData.parts_data.items"
              :id="'parts' + item.id"
              :key="'parts' + item.id"
              :row-data="item"
              :row-index="index"
              :brand-options.sync="brandOptions"
              @removePartItem="removePartItem"
            />
            <b-row>
              <b-col cols="5">
                <b-button
                  variant="outline-success"
                  class="btn-icon"
                  @click="repeatPartsAgain"
                >
                  <feather-icon
                    icon="PlusCircleIcon"
                    class="text-success"
                  />
                  Новая строка
                </b-button>
              </b-col>
              <b-col cols="3">
                <strong>Сумма: </strong> {{ calculatePartsSum }}
              </b-col>
              <b-col cols="4">
                <strong>Сумма со скидкой: </strong> {{ calculatePartsSumDiscount }}
              </b-col>
            </b-row>
            <hr style="margin-top:20px;">
            <b-row>
              <b-col cols="5" />
              <b-col cols="3">
                <strong>Всего: </strong> {{ calculateWorkSum+calculatePartsSum }}
              </b-col>
              <b-col cols="4">
                <strong>Всего со скидкой: </strong> {{ calculateWorkSumDiscount+calculatePartsSumDiscount }}
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Рекомендуемый дополнительный ремонт:"
                  label-for="recomended"
                >
                  <b-form-textarea
                    id="recomended"
                    v-model="ordersData.recomended"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <p>Исполнитель:</p>
              </b-col>
              <b-col cols="10">
                <b-form-select
                  id="executor"
                  v-model="ordersData.executor"
                  :options="allUsers"
                  :text-field="'full_name'"
                  :value-field="'id'"
                />
              </b-col>
            </b-row>

            <b-row class="mt-1">
              <b-col cols="2">
                <p>Cрок гарантии на работу:</p>
              </b-col>
              <b-col cols="10">
                <b-form-input v-model="ordersData.warranty_period" />
              </b-col>
            </b-row>

            <b-row class="mt-1">
              <b-col cols="2">
                <p>Cрок гарантии на запчасти:</p>
              </b-col>
              <b-col cols="10">
                <b-form-input v-model="ordersData.warranty_period_parts" />
              </b-col>
            </b-row>

            <b-row class="mt-1">
              <b-col cols="2">
                <p>Статус:</p>
              </b-col>
              <b-col cols="10">
                <b-form-select
                  id="status_zakaz_naryad"
                  v-model="ordersData.status_order"
                  :options="[{id: 1, name: 'Новый заказ'},{id: 3, name: 'Завершено'},{id: 4, name: 'Резерв'}]"
                  :text-field="'name'"
                  :value-field="'id'"
                />
              </b-col>
            </b-row>
          </tab-content>
          <template slot="finish">
            <span />
          </template>
          <template
            v-if="this.$router.history.current.name !== 'orders-duplicate'"
            slot="custom-buttons-left"
          >
            <router-link :to="{name: 'orders-duplicate', params: {id: this.$route.params.id}}">
              <b-button
                class="float-left mr-1"
                size="lg"
              >
                Дублировать
              </b-button>
            </router-link>
          </template>
          <template slot="custom-buttons-right">
            <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              split
              right
              size="lg"
              variant="primary"
              :text="ordersData.id ? 'Сохранить' : 'Сохранить'"
              @click="formSubmit('save')"
            >
              <b-dropdown-item @click="formSubmit('osmotr')">
                Открыть акт осмотра автомобиля
              </b-dropdown-item>
              <b-dropdown-item @click="formSubmit('akt')">
                Открыть акт приема-передачи
              </b-dropdown-item>
              <b-dropdown-item @click="formSubmit('akt_agregat')">
                Открыть акт агрегата
              </b-dropdown-item>
              <b-dropdown-item @click="formSubmit('naryad')">
                Открыть заказ-наряд
              </b-dropdown-item>
              <b-dropdown-item @click="formSubmit('akt_work')">
                Открыть заказ-наряд для юр.лиц
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </form-wizard>
      </b-col>
    </b-row>

  </b-form>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow, BCol, BForm, BButton, BFormInput, BFormGroup, BFormSelect, BFormTextarea, BFormCheckbox, BDropdown,
  BDropdownItem, BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.ru'
import flatPickr from 'vue-flatpickr-component'
import WorkRow from '@/views/orders/works/WorkRow.vue'
import PartRow from '@/views/orders/works/PartRow.vue'
import axiosIns from '@/libs/axios'

export default {
  name: 'OrdersForm',
  components: {
    WorkRow,
    PartRow,
    BButton,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    BFormCheckbox,
    FormWizard,
    TabContent,
    BDropdown,
    BDropdownItem,
    Cleave,
    BInputGroup,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: ['ordersData', 'allCompanies', 'allUsers'],
  data() {
    return {
      options: {
        DelimetrGosNomer: {
          delimiters: [' ', ' ', ' '],
          blocks: [1, 3, 2, 3],
          uppercase: true,
        },
        DelimetrPhone: {
          phone: true,
          prefix: '+7',
          phoneRegionCode: 'RU',
        },
      },
      brandOptions: [],
    }
  },
  computed: {
    calculateWorkSum() {
      let sumWorkAll = 0
      const getAllWorkSum = this.ordersData.works_data.items
      getAllWorkSum.forEach(a => {
        // eslint-disable-next-line radix,no-restricted-globals
        if (!isNaN(parseInt(a.sum_work))) {
          // eslint-disable-next-line radix
          sumWorkAll += parseInt(a.sum_work)
        }
      })
      return sumWorkAll
    },
    calculateWorkSumDiscount() {
      let sumWorkAllDiscount = 0
      const getAllWorkSum = this.ordersData.works_data.items
      getAllWorkSum.forEach(a => {
        // eslint-disable-next-line radix,no-restricted-globals
        if (!isNaN(parseInt(a.discount_work)) && !isNaN(parseInt(a.sum_work))) {
          // eslint-disable-next-line radix
          sumWorkAllDiscount += (parseInt(a.sum_work) - parseInt(parseInt(a.sum_work) * (parseInt(a.discount_work) / 100)))
        } else {
          // eslint-disable-next-line radix,no-restricted-globals,no-lonely-if
          if (!isNaN(parseInt(a.sum_work))) {
            // eslint-disable-next-line radix
            sumWorkAllDiscount += parseInt(a.sum_work)
          }
        }
      })
      return sumWorkAllDiscount
    },
    calculatePartsSum() {
      let sumPartskAll = 0
      const getAllPartsSum = this.ordersData.parts_data.items
      getAllPartsSum.forEach(a => {
        // eslint-disable-next-line radix,no-restricted-globals
        if (!isNaN(parseInt(a.sum_part))) {
        // eslint-disable-next-line radix
          sumPartskAll += parseInt(a.sum_part)
        }
      })
      return sumPartskAll
    },
    calculatePartsSumDiscount() {
      let sumPartsAllDiscount = 0
      const getAllPartsSum = this.ordersData.parts_data.items
      getAllPartsSum.forEach(a => {
        // eslint-disable-next-line radix,no-restricted-globals
        if (!isNaN(parseInt(a.discount_part)) && !isNaN(parseInt(a.sum_part))) {
          // eslint-disable-next-line radix
          sumPartsAllDiscount += (parseInt(a.sum_part) - parseInt(parseInt(a.sum_part) * (parseInt(a.discount_part) / 100)))
        } else {
          // eslint-disable-next-line radix,no-restricted-globals,no-lonely-if
          if (!isNaN(parseInt(a.sum_part))) {
          // eslint-disable-next-line radix
            sumPartsAllDiscount += parseInt(a.sum_part)
          }
        }
      })
      return sumPartsAllDiscount
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.activateAll.activateAll()
      Promise.all([
        this.loadBrandOptions(),
      ]).then(() => {
        this.loading = false
      })
    })
  },
  methods: {
    formSubmit(type = 'save') {
      this.$emit('formSubmit', type)
    },
    repeatPartsAgain() {
      this.ordersData.parts_data.items.push({
        id: this.ordersData.parts_data.nextTodoId += 1,
        count_part: 1,
      })
    },
    repeatWorksAgain() {
      this.ordersData.works_data.items.push({
        id: this.ordersData.works_data.nextTodoId += 1,
        count_work: 1,
      })
    },
    removeWorkItem(index) {
      this.ordersData.works_data.items.splice(index, 1)
    },
    removePartItem(index) {
      this.ordersData.parts_data.items.splice(index, 1)
    },
    loadBrandOptions() {
      const req = axiosIns({
        method: 'GET',
        url: '/brands/short-list',
      })

      req.then(response => {
        // const defaultBrands = [{ id: null, name: ' ---------- ' }]
        // defaultBrands.push(...response.data.data)
        this.brandOptions = response.data.data
      })

      return req
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
