<template>
  <b-row
    ref="row"
  >
    <b-col cols="12">
      <b-row>
        <b-col cols="5">
          <b-form-group
            label="Наименование запчасти:"
            :label-for="`name_part`+ rowData.id"
          >
            <b-form-input
              :id="`name_part`+ rowData.id"
              v-model="rowData.name_part"
              size="sm"
            />
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-form-group
            label="Бренд:"
            :label-for="`brand_part`+ rowData.id"
          >
            <v-select
              :id="`brand_part`+ rowData.id"
              v-model="rowData.brand_part"
              :options="brand_options"
              label="name"
              :reduce="option => option.id"
            />
            <!--            @search="onSearch"-->
          </b-form-group>
        </b-col>

        <b-col cols="2">
          <b-form-group
            label="Артикул:"
            :label-for="`article_part`+ rowData.id"
          >
            <b-form-input
              :id="`article_part`+ rowData.id"
              v-model="rowData.article_part"
              size="sm"
            />
          </b-form-group>
        </b-col>

        <b-col cols="2">
          <b-form-group
            label="Поставщик:"
            :label-for="`provider_part`+ rowData.id"
          >
            <b-form-select
              :id="`provider_part`+ rowData.id"
              v-model="rowData.provider_part"
              :options="[{'id': null, name: '---'},{'id': 1, name: 'Росско'},{'id': 2, name: 'Автоконтинент'},{'id': 3, name: 'Евроавто'},{'id': 4, name: 'Автоевро'},{'id': 5, name: 'Микадо'},{'id': 6, name: 'Армтек'},{'id': 7, name: 'АвтономияПРО'},{'id': 8, name: 'Кварто'},{'id': 9, name: 'Авито'},{'id': 10, name: 'Автомобильномсь'},{'id': 11, name: 'ДромБаза'},{'id': 12, name: 'Русь'},{'id': 13, name: 'Сторонний поставщик'}]"
              :text-field="'name'"
              :value-field="'id'"
              size="sm"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="2">
          <b-form-group
            label="Кол-во:"
            :label-for="`count_part`+ rowData.id"
          >
            <b-form-input
              :id="`count_part`+ rowData.id"
              v-model="rowData.count_part"
              size="sm"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            label="Зкп. цена:"
            :label-for="`purchasing_price_part`+ rowData.id"
          >
            <b-form-input
              :id="`purchasing_price_part`+ rowData.id"
              v-model="rowData.purchasing_price_part"
              size="sm"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            label="Цена:"
            :label-for="`price_part`+ rowData.id"
          >
            <b-form-input
              :id="`price_part`+ rowData.id"
              v-model="rowData.price_part"
              size="sm"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            label="Cумма:"
            :label-for="`sum_part`+ rowData.id"
          >
            <b-form-input
              :id="`sum_part`+ rowData.id"
              v-model="rowData.sum_part"
              size="sm"
              disabled
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            label="Скидка:"
            :label-for="`discount_part`+ rowData.id"
          >
            <b-form-input
              :id="`discount_part`+ rowData.id"
              v-model="rowData.discount_part"
              size="sm"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="1"
        >
          <b-button
            variant="outline-danger"
            class="btn-icon mt-2"
            @click="removePartItem(rowIndex)"
          >
            <feather-icon
              icon="Trash2Icon"
              class="text-danger"
            />
          </b-button>
        </b-col>
      </b-row>
      <hr>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton, BCol, BFormGroup, BFormInput, BFormSelect, BRow,
} from 'bootstrap-vue'
import Vue from 'vue'
import vSelect from 'vue-select'

export default {
  name: 'WorkRow',
  components: {
    BFormSelect,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  props: ['rowData', 'rowIndex', 'brandOptions'],
  computed: {
    brand_options() {
      return this.brandOptions.map(({ name, id }) => ({ name, id }))
    },
  },
  watch: {
    'rowData.count_part': {
      handler(item) {
        Vue.set(this.rowData, 'sum_part', item * this.rowData.price_part)
      },
    },
    'rowData.price_part': {
      handler(item) {
        Vue.set(this.rowData, 'sum_part', item * this.rowData.count_part)
      },
    },
  },
  methods: {
    removePartItem(index) {
      this.$emit('removePartItem', index)
    },
  },
}
</script>

<style>
  .mp {
    margin: 0px;
    padding: 0px;
  }
</style>
