<template>
  <b-row
    ref="row"
  >
    <b-col cols="5">
      <b-form-group
        label="Наименование работы:"
        :label-for="`name_work`+ rowData.id"
      >
        <b-form-input
          :id="`name_work`+ rowData.id"
          v-model="rowData.name_work"
          size="sm"
        />
      </b-form-group>
    </b-col>
    <b-col cols="2">
      <b-form-group
        label="Исполнитель:"
        :label-for="`executor_work`+ rowData.id"
      >
        <b-form-select
          :id="`executor_work`+ rowData.id"
          v-model="rowData.executor_work"
          :options="[{'id': null, name: '---'}]"
          :text-field="'name'"
          :value-field="'id'"
          size="sm"
          disabled
        />
      </b-form-group>
    </b-col>
    <b-col cols="1">
      <b-form-group
        label="Кол-во:"
        :label-for="`count_work`+ rowData.id"
      >
        <b-form-input
          :id="`count_work`+ rowData.id"
          v-model="rowData.count_work"
          size="sm"
          type="number"
        />
      </b-form-group>
    </b-col>
    <b-col cols="1">
      <b-form-group
        label="Цена:"
        :label-for="`price_work`+ rowData.id"
      >
        <b-form-input
          :id="`price_work`+ rowData.id"
          v-model="rowData.price_work"
          size="sm"
          type="number"
        />
      </b-form-group>
    </b-col>
    <b-col cols="1">
      <b-form-group
        label="Cумма:"
        :label-for="`sum_work`+ rowData.id"
      >
        <b-form-input
          :id="`sum_work`+ rowData.id"
          v-model="rowData.sum_work"
          size="sm"
          disabled
          type="number"
        />
      </b-form-group>
    </b-col>
    <b-col cols="1">
      <b-form-group
        label="Скидка:"
        :label-for="`discount_work`+ rowData.id"
      >
        <b-form-input
          :id="`discount_work`+ rowData.id"
          v-model="rowData.discount_work"
          size="sm"
          type="number"
        />
      </b-form-group>
    </b-col>
    <b-col cols="1">
      <b-button
        variant="outline-danger"
        class="btn-icon mt-2"
        size="sm"
        @click="removeWorkItem(rowIndex)"
      >
        <feather-icon
          icon="Trash2Icon"
          class="text-danger"
        />
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton, BCol, BFormGroup, BFormInput, BFormSelect, BRow,
} from 'bootstrap-vue'
import Vue from 'vue'

export default {
  name: 'WorkRow',
  components: {
    BFormSelect,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BFormGroup,
  },
  props: ['rowData', 'rowIndex'],
  watch: {
    'rowData.count_work': {
      handler(item) {
        Vue.set(this.rowData, 'sum_work', item * this.rowData.price_work)
      },
    },
    'rowData.price_work': {
      handler(item) {
        Vue.set(this.rowData, 'sum_work', item * this.rowData.count_work)
      },
    },
  },
  methods: {
    removeWorkItem(index) {
      this.$emit('removeWorkItem', index)
    },
  },
}
</script>
